/* fonts */
@font-face
  font-family GothamPro
  src url("/static/dist/fonts/GothamPro-Bold.ttf") format("truetype")
  font-style normal
  font-weight 700

@font-face
  font-family ProximaNova
  src url("/static/dist/fonts/ProximaNova-Regular.ttf") format("truetype")
  font-style normal
  font-weight 400

@font-face
  font-family ProximaNovaBold
  src url("/static/dist/fonts/ProximaNova-Bold.ttf") format("truetype")
  font-style normal
  font-weight 400

@font-face
  font-family ProximaNovaSemibold
  src url("/static/dist/fonts/ProximaNova-Semibold.ttf") format("truetype")
  font-style normal
  font-weight 400